import VueI18n from 'vue-i18n'
import {
  NdefRecord,
  Nfc,
  NfcTag,
  NfcUtils
} from '@capawesome-team/capacitor-nfc'
import { Capacitor } from '@capacitor/core'

type NfcApiResult = {
  nfcTag?: NfcTag
  error?: string
  cancelled?: boolean
}

export class NfcApi {
  private _isSupported: boolean = false
  private _isEnabled: boolean = false
  private utils: NfcUtils = new NfcUtils()

  constructor(private readonly i18n: VueI18n) {
    this.setupModule()
  }

  public get isEnabled() {
    return this._isEnabled
  }

  public get isSupported() {
    return this._isSupported
  }

  public openSettings() {
    return Nfc.openSettings()
  }

  public getTextFromNdefTextRecord(record: NdefRecord) {
    return this.utils.getTextFromNdefTextRecord({ record })
  }

  public read(): Promise<NfcApiResult> {
    return new Promise((resolve) => {
      Nfc.addListener('nfcTagScanned', ({ nfcTag }) => {
        resolve({ nfcTag })
        this.stopScanSession()
      })
      Nfc.addListener('scanSessionCanceled', () => {
        resolve({ cancelled: true })
        this.stopScanSession()
      })
      Nfc.addListener('scanSessionError', ({ message }) => {
        resolve({ error: message })
        this.stopScanSession()
      })

      Nfc.startScanSession({
        alertMessage: this.i18n.tc('image_plate.scan_alert_message')
      })
    })
  }

  public async stopScanSession() {
    await Nfc.stopScanSession()
    return Nfc.removeAllListeners()
  }

  public async setupModule() {
    const { isSupported } = await Nfc.isSupported()
    if (!isSupported) return
    this._isSupported = true
    this._isEnabled = true

    if (Capacitor.getPlatform() === 'android') {
      const { isEnabled } = await Nfc.isEnabled()
      this._isEnabled = isEnabled
    }
  }
}
