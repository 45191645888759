import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=0c7f23e3"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,AtomsAlertPulse: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/alert/Pulse.vue').default,MoleculesNavigationInner: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/navigation/Inner.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default})
